/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import XButton from 'vux/src/components/x-button';
import ViewBox from 'vux/src/components/view-box';
export default {
  name: 'OutsideForm',
  components: {
    Group: Group,
    XButton: XButton,
    ViewBox: ViewBox,
    Cell: Cell
  },
  data: function data() {
    return {
      id: '',
      wxData: {},
      copyer: []
    };
  },
  computed: {},
  methods: {
    selectWxByPrimaryKey: function selectWxByPrimaryKey() {
      var _this = this;

      this.$api.Attence.selectWxByPrimaryKey(this.id).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.wxData = data;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    selectCopyers: function selectCopyers() {
      var _this2 = this;

      this.copyer = [];
      this.$api.Copyer.selectCopyUser({
        lx: 7,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        var code = response.code,
            data = response.data;

        if (+code !== _this2.$code.success) {// this.$utils.Tools.toastShow(msg);
        } else {
          var _this2$copyer;

          (_this2$copyer = _this2.copyer).push.apply(_this2$copyer, _toConsumableArray(data));
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    submitData: function submitData() {
      var _this3 = this;

      var params = {
        shenhe: this.$utils.Store.getItem('userSerial'),
        id: this.id
      };
      this.$api.Attence.updateByPrimaryKey(params).then(function (response) {
        var code = response.code,
            msg = response.msg;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.$utils.Tools.toastShow(msg);

          var that = _this3;
          setTimeout(function () {
            that.$router.replace('/OutsideList');
          }, 1500);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.selectWxByPrimaryKey();
    this.selectCopyers();
  },
  mounted: function mounted() {
    this.$utils.Store.setItem('tabItem', this.$route.query.tabItem);
  },
  destroyed: function destroyed() {}
};